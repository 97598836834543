@import url('https://fonts.googleapis.com/css?family=Poppins');
@font-face {
	font-family: 'SF Pro Text';
	src: url('../fonts/SFProText-Regular.eot');
	src: url('../fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Regular.woff2') format('woff2'), url('../fonts/SFProText-Regular.woff') format('woff'), url('../fonts/SFProText-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@import 'partials/variables';
@import 'partials/texts';
@import 'partials/buttons';
@import 'partials/common';
@import 'partials/layout';
@import 'partials/helper';
@import 'partials/controls';
@import 'partials/body';
@import 'common';
