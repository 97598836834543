// Background colour
body {
  background: #f8f9fd !important;
}

// Scrollbar styles
*::-webkit-scrollbar {
  width: 10px;
  margin-right: 5px;
  padding-right: 5px;
  background-color: whitesmoke;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 20px 20px 20px 20px ;
}
