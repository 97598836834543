@import '../../customStyles/scss/partials/variables';

.snackbar-div {
  padding: 15px;
  background-color: green;
  color: white;
  border-radius: 4px;
  position: relative;
  left: 0;
}

.invite-container {
  .cross-button {
    button {
      .MuiIconButton-label {
        color: $color-primary;
      }
    }
  }

  .share-button {
    color: $color-white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-section {
    .popup-bg {
      background-color: $color-white;
      padding: 35px 75px;
      border-radius: 10px;

      .popup-image {
        height: 100px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      .price {
        color: $color-primary;
      }

      .email-align {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;

        .send-button {
          height: 38px;
        }
      }

      .share-links {
          justify-content: center;
          padding-top: 10px;

          .fb {
            @extend .share-button;
            background-color: #3b579d;
          }

          .tw {
            @extend .share-button;
            background-color: #00acee;
          }

          .in {
            @extend .share-button;
            background-color: #0e76a8;
          }

          .link {
            @extend .share-button;
            background-color: $color-primary;
            margin: 6px 0 0 10px;

            .MuiIconButton-label {
              &:hover {
                color: $color-white !important;
              }
            }

            .clip-icon {
              padding: 2px;
              cursor: pointer;
              &:hover {
                color: $color-white;
              }
              path {
                &:hover {
                  color: $color-white;
                }
              }
            }
          }
      }
    }
  }
}

.invite-success-box {
  height: 60vh;
}
