// Box Styles

.custom-box {
  background-color: $color-white;
  border: 1px solid $color-border;
  padding: 20px;
  border-radius: 8px;
  box-shadow: none;

  &.no-padding {
    padding: 0;
  }
}

// Custom Modal
.custom-modal {
  .MuiDialog-paperScrollPaper {
    border-radius: 8px;
    padding: 0.5rem 0.5rem;
    height: 80%;

  }

  &.auto-height {
    .MuiDialog-paperScrollPaper {
      height: auto;
    }
  }

  .header {
    display: inline-flex;
    justify-content: space-between;

    h2 {
      margin-right: 30px;
    }

    .cross-button {
      margin-right: 0.5rem;
      button {
        .MuiIconButton-label {
          color: $color-primary;
        }
      }
    }
  }

  .modal-actions {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    button {
      margin: 0 0.5rem;
    }
  }
}
