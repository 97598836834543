/* Headings
==========================================================*/

%headings {
  text-align: left;
  color: $color-headings;
  text-transform: none;
  letter-spacing: 0.3px;
  opacity: 1;

  &.unbold {
    font-weight: normal;
  }

  &.light {
    color: $color-dark-grey;
  }

  &.primary {
    color: $color-primary;
  }
}

.h1 {
  @extend %headings;
  font: normal normal 600 50px/76px $system-font;
}

.h2 {
  @extend %headings;
  font: normal normal 600 32px/48px $system-font;
}

.h3 {
  @extend %headings;
  font: normal normal 600 18px/27px $system-font;
}

.h4 {
  @extend %headings;
  font: normal normal 600 14px/21px $system-font;

  &.light {
    color: $color-dark-grey;
  }

  &.fw-md {
    font-weight: 500;
  }

  &.sz-sm {
    font: normal normal 600 13px/10px $system-font;
  }
}

/* Paragraphs
==========================================================*/

.para {
  font: normal normal normal 14px/20px $pro-font;
  text-align: left;
  letter-spacing: 0px;
  color: $color-paragraph;
  opacity: 1;

  &.bold {
    font-weight: bold;
  }

  &.heading-color {
    color: $color-headings;
  }

  &.light {
    color: $color-dark-grey;
  }

  &.primary {
    color: $color-primary;
  }

  &.red {
    color: $color-red;
  }

  &.italic {
    font-style: italic;
  }

  &.sz-sm {
    font-size: 13px;
  }

  &.sz-xs {
    font-size: 12px;
  }

  &.sz-lg {
    font-size: 15px;
  }

  &.sz-xl {
    font-size: 16px;
  }

}

/* Text Links
==========================================================*/

.text-link {
  font: normal normal normal 14px/25px $pro-font;
  text-align: left;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
  cursor: pointer;
}

.text-link-underlined {
  @extend .text-link;
  color: $color-primary-dark;
  text-decoration: underline;
}
