/* Colors
=====================================================*/
$color-primary: #4877F4;
$color-primary-dark:#3258BE;
$color-primary-light: #D5DFFC;
$color-background: #F8F9FD;
$color-info: #34C1FF;
$color-headings:#444F5F;
$color-paragraph: #4A4A4A;
$color-dark-grey:#999999;
$color-light-grey:#cecece;
$color-border:#DBDBDB;
$color-white: #FFFFFF;
$color-red: #F14667;
$color-yellow: #FFDD57;
$color-green: #23E8B5;
$color-light-brown: #6b6b6b;
$color-pink: #FCDAE0;

/* Texts
=====================================================*/
$button-label: 'SF Pro Text';
$system-font: 'Poppins', sans-serif;
$pro-font: 'SF Pro Text';
