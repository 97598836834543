/* Button Styles
========================================================================== */

// Primary Default
.primary-default {
  background: $color-primary 0% 0% no-repeat padding-box;

  &:hover {
    background-color: $color-primary-dark;
  }
  &:disabled {
    background-color: $color-primary-light;
  }
}

// Secondary Default
@mixin secondary-default($front-color) {
  color: $front-color;
  background: $color-white 0% 0% no-repeat padding-box;
  border: 1px solid $front-color;

  &:hover {
    background-color: $front-color;
    color: $color-white;
  }

  &:disabled {
    border: 1px solid $color-primary-light;
    color: $color-primary-light;
  }
}

/* Material UI over-ride Button root Styles
========================================================================== */

.MuiButtonBase-root {
  text-align: center;
  opacity: 1;

  &.wide-button {
    width: 100%;
  }

// Primary Large Default
  &.button-primary-large {
    @extend .primary-default;
    min-width: 145px;
    height: 40px;
    border-radius: 6px;
  }

// Primary Small Default
  &.button-primary-small {
    @extend .primary-default;
      min-width: 86px;
      height: 30px;
      border-radius: 4px;
  }

// Secondary Large Default
  &.button-secondary-large {
    @include secondary-default($color-primary);
    min-width: 137px;
    height: 40px;
    border-radius: 6px;
  }

// Secondary Small Default
  &.button-secondary-small {
    @include secondary-default($color-primary);
    min-width: 86px;
    height: 30px;
    border-radius: 4px;
  }

// Secondary Red Small Button
  &.button-secondary-small-red {
    @include secondary-default($color-red);
    min-width: 86px;
    height: 30px;
    border-radius: 4px;
  }
// Secondary Red Large Button
  &.button-secondary-large-red {
    @include secondary-default($color-red);
    min-width: 137px;
    height: 40px;
    border-radius: 4px;
  }

  &.static {
    @include secondary-default($color-white);
  }

// Primary Text Button
  &.button-primary-text {
    height: auto;
    padding: 0 10px;
    width: fit-content;
    color: $color-primary;
    background-color: $color-white;

    &.bold {
      font-weight: 600;
    }

    &.small-height {
      height: 30px;
    }

    &.large-height {
      height: 40px;
    }

    &.center {
      align-self: center;
    }

    &:disabled {
      color: $color-dark-grey;
    }
  }

  // Set full height
  &.is-fullheight {
    height: 100%;
  }
}

/* Button Label Styles
========================================================================== */

// Primary Button Label
.primary-label {
  color: $color-white;
  min-width: max-content;

  &:hover {
    color: $color-white;
  }
}

// Secondary Button Label
.secondary-label {

  &:hover {
    color: $color-white;
  }
}

/* Material UI over-ride Button label Styles
========================================================================== */
.MuiButton-label {
  font-family: $button-label;
  text-transform: none;
  text-align: left;
  letter-spacing: 0px;

  // Primary Large Label
  &.button-primary-large-label {
    @extend .primary-label;
    font-size: 17px;
  }

  // Primary Small Label
  &.button-primary-small-label {
    @extend .primary-label;
    font-size: 14px;
  }

  // Secondary Large Label
  &.button-secondary-large-label {
    @extend .secondary-label;
    font-size: 17px;
  }

  // Secondary Small Label
  &.button-secondary-small-label {
    @extend .secondary-label;
    font-size: 14px;
  }

  // Text button label
  &.button-primary-text-label {

    &:hover {
      color: $color-primary-dark;
    }
    &.underlined {
      text-decoration: underline;
    }
    &.dark {
      color: $color-dark-grey;
    }
    &.red {
      color: $color-red;
    }
  }
}
