.text-button {
  width: 100%;
  padding: 0px;
  border: none;
  background: #f4f4f4;
  color: #7f00ff;
  font-size: 15px;
  outline: none;
  cursor: pointer;
} :hover {
  color: black
}

.steps-icons {
  margin: auto;
  width: 100% !important;
  height: 30px;
}

.cross-button {
  position: absolute;
  right: 0;
}

.custom-login-button {
  transition: all 0.5s;
  line-height: 0 !important;
  color: white;
  padding: 22px 40px !important;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 500px;
  justify-content: center;
  opacity: 0.8;

  &:hover {
    color: white !important;
    background-color: #3273dc !important;
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    opacity: 0.4;
  }

  &:focus {
    outline: unset;
  }
}

.custom-signup-button {
  transition: all 0.5s;
  line-height: 0 !important;
  color: white;
  padding: 22px 40px !important;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 500px;
  border-width: 2px;
  border-color: #3273dc !important;
  //color: #7f00ff !important;
  background-color: #3273dc !important;
  justify-content: center;
  opacity: 0.8;

  &:hover {
    color: white !important;
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    opacity: 0.6;
  }

  &:focus {
    outline: unset;
  }
}
