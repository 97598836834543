/*
  Checkboxes
  Radio-buttons
  Custom Active tabs
  Custom Input Box
  Drop Down Bar
  Editable Text

*/



/* Control button common styles */
%control-buttons {
  .MuiTypography-root {
    @extend .para;
  }

  .MuiIconButton-root {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: inherit;

    .MuiSvgIcon-root {
      font-size: 1.2rem;
      color: $color-primary;
    }
  }
}

// Radio Buttons
.radio-buttons {
  @extend %control-buttons;

  &.unset-label {
    .MuiTypography-root {
      font: normal normal normal 16px/24px 'SF Pro Text';
    }
  }

  &.label-sz-sm {
    .MuiTypography-root {
      font: normal normal normal 14px/20px 'SF Pro Text';
    }
  }

  .MuiRadio-colorSecondary {
    color: $color-primary !important;

    .Mui-checked:hover {
      color: #ffffff;
    }
  }
}

// Check boxes
.checkboxes {
  @extend %control-buttons;

  &.unset-label {
    .MuiTypography-root {
      font: normal normal normal 16px/24px 'SF Pro Text';
    }
  }

  .MuiCheckbox-colorSecondary {
    color: $color-primary !important;
    .Mui-checked:hover {
      background-color: #ffffff;
    }
  }
}

.switches {
  &.MuiSwitch-root {

    .MuiSwitch-switchBase {
      color: $color-primary !important;
    }

    .MuiSwitch-colorSecondary{
      color: $color-primary !important;
    }

    .MuiSwitch-track {
      background-color: $color-white;
      border: 3px solid $color-primary-dark;
    }

    :hover {
      color: $color-primary !important;
    }
  }
}

/* Custom Active Tabs styles */
.custom-active-tabs {
  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTabs-fixed {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .MuiTouchRipple-root {
    opacity: 0;
  }

  .active-tab {
    margin: 0px 5%;

    .MuiTab-wrapper {
      @extend .h3;
      text-transform: none;
    }
  }

  .inactive-tab {
    @extend .active-tab;
    .MuiTab-wrapper {
      color: $color-dark-grey;
      font-weight: 400;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-primary;
  }
}

/* Custom Input box */
.input-box {
  border: 1px solid $color-border;
  border-radius: 8px;
  padding: 10px;

  textarea {
    @extend .para;
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 100%;
    border: none;
    resize: none;

    &:focus {
      outline: none;
      box-shadow: inherit;
    }
  }
}

/* Custom Drop down bar */
.drop-down-bar {
  width: 100%;

  .MuiFormLabel-root {
    padding: 0px 20px 0px 0px;
    @extend .para;
    color: $color-dark-grey;
  }

  &:focus {
    outline: none;
  }
  &:hover {
    color: $color-border;
  }
  .MuiInputBase-root {
    height: auto;
    @extend .para;
    color: $color-dark-grey;
  }
  .MuiInputBase-input {
    height: auto;
  }
}

// Editable text
.text-edit {
  align-self: center;
  &.MuiInput-underline:before {
    border-bottom: none;
  }
  &.MuiInputBase-root {
    @extend .para;
  }
}
