@import './mixins';

// Rating Star
.MuiRating-root {
  &.rating-star {
    color: $color-primary;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: text-bottom;

    &.no-margin {
      margin: 0;
    }

    &:hover, :active {
      color: $color-primary;
    }

    .rating-star-label {
      color: $color-primary;
    }
  }
}

// Input search
.search-input {
  width: 100%;
  height: 40px;
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: 8px;
  margin: auto;
  padding: 5px;
  opacity: 1;

  .input-field {
    @extend .para;
    height: auto;
    width: calc(100% - 36px);
    padding-left: 10px;
  }
}

// Fa Icon
.custom-fa-icon {
  font-size: 16px;
  color: $color-primary;
  @include fa-icon-hover($color-primary);

  &.light {
    color: $color-dark-grey;
    @include fa-icon-hover($color-dark-grey);
  }

  &.dark {
    color: $color-headings;
    @include fa-icon-hover($color-headings);
  }

  &.shallow {
    color: white;
    font-size: 20px;
    @include fa-icon-hover($color-headings);
    @include fa-icon-outlined($color-headings);
  }

  &.red {
    color: $color-red;
    @include fa-icon-hover($color-red);
  }

  &.white {
    color: $color-white;
    @include fa-icon-hover($color-white);
  }

  &.pointer {
    cursor: pointer;
  }

  // Extra small
  &.sz-xs {
    font-size: 12px;
  }

  // Small
  &.sz-sm {
    font-size: 14px;
  }

  // Medium
  &.sz-md {
    font-size: 18px;
  }


  // Large
  &.sz-lg {
    font-size: 20px;
  }

  // Extra Large
  &.sz-xl {
    font-size: 24px;
  }

  // Double Extra large
  &.sz-xxl {
    font-size: 34px;
  }
}

// List Divider
.list-divider {
  margin: 15px 0 !important;
  border-bottom: 1px solid $color-border !important;

  &:last-child {
    border: none !important;
  }

  &.mtb-10 {
    margin: 10px 0 !important;
  }

  &.no-margin {
    margin: 0;
  }
}

// Profile pic
.profile-pic {
  width: 56px;
  height: 56px;
  margin: 10px;

  &.large {
    width: 65px;
    height: 65px;
  }

  &.small {
    width: 25px;
    height: 25px;
  }
}

// Tags set
.tags-set {
  .tag-chip {
    @extend .para;
    margin-right: 15px;
    margin-top: 10px;
    padding: 0px 5px;
    background-color: $color-primary-light !important;

    .MuiSvgIcon-root {
      @extend .custom-fa-icon;
      color: $color-dark-grey;
    }
  }

  &.small {
    .tag-chip {
      font-size: 13px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  .more {
    color: $color-primary;
    background-color: inherit;
    margin: 0px 5px;
    vertical-align: bottom;
    & :hover {
      color: $color-primary-dark;
    }
  }
}

// Divider
.MuiDivider-root {
  &.divider {
    width: 100%;
    margin: 15px 0;
  }
}


// Primary text link
.primary-text-link {
  @extend .text-link;

  &:hover {
    @extend .text-link-underlined;
  }
}

// Error overlay box
.display-error-root {
  &.box {
    color: $color-pink;
    // z-index: 10000;
  }
  .heading {
    @extend .h3;
    text-align: center;
    font-weight: 600;
  }
  .error-message {
    @extend .para;
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
  }
}

// Text Divider
.text-divider {
  width: 100%;
  margin: 10px 0;
  .side {
    align-self: center;

    .border {
      align-self: center;
      border-bottom: 1px solid $color-border;
    }
  }

  .middle {
    text-align: center;
    align-self: center;

    .centered-text {
      @extend .para;
      color: $color-dark-grey;
    }
  }
}

// Text input field
.custom-text-input-field {
  @extend .para;
  border: 1px solid $color-border;
  border-radius: 6px;
  background: $color-white;
  padding: 10px;
  outline: none;
}

.custom-svg-icon{
  &.color-primary {
    path, g, circle {
      stroke: $color-primary;
    }
  }
}

// Ellipsis Options
.ellipsis-options-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-around;
  border-radius: 8px;
  padding: 5px;

  .option {
    display: flex;
    justify-content: flex-start;
    background-color: $color-white;
    .option-icon {
      flex: 1;
      margin: auto;
    }
    .option-name {
      flex: 2;
      margin: 5px;
      font-size: 14px;
    }
  }
  .option-label {
    margin: 2px 6px;
  }
}

.text-field-para {
  input {
    @extend .para;
  }
}
